export default class PsConfig {

    static versionNo : String = '1.1';

    ///
    /// API Key to connection with Main Backend
    /// This key must equal with key from backend
    ///
    static apiKey: String = "teampsisthebest";

    ///
    /// Live Mode
    /// ------------
    /// If you want to upload to server, please enable below and comment Development below
    ///
    // static fixedDomain: String = "https://ozbon.ng/flutter-buysell-demo"; 
    // static domain: String = "https://ozbon.ng/flutter-buysell-demo"; 

    ///
    /// Development Mode
    /// -----------------
    /// Before upload to server, please comment this 2 lines.
    ///
    static fixedDomain: String = process.env.NODE_ENV === 'production' ? 'https://ozbon.ng/admin' : "http://ozbon.local/admin";
    static domain: String = process.env.NODE_ENV === 'production' ? "https://ozbon.ng/admin" : "http://localhost:3000";
        

    ///
    /// Firebase Config
    ///
    static firebaseConfig = {
        apiKey: "AIzaSyDzxTXRbVYfQbZt-iQCf7o3_AyJylHKfSw",
        authDomain: "ozbon-6f57c.firebaseapp.com",
        databaseURL: "https://ozbon-6f57c-default-rtdb.firebaseio.com/",
        projectId: "ozbon-6f57c",
        storageBucket: "ozbon-6f57c.appspot.com",
        messagingSenderId: "236201010814",
        appId: "1:236201010814:web:a0622a51b40a08c0915a2f",
        measurementId: "G-JQYC8YM8F7"            
    };
    
    
    ///
    /// Base URL * Don't update it *
    ///
    static base: String = PsConfig.domain; 
    static baseUrl: String = PsConfig.domain + '/index.php';
    static fixedPath : String = PsConfig.fixedDomain;
    
    
    /// Google Map Key
    /// ----------------
    static mapKey: String = "AIzaSyDzxTXRbVYfQbZt-iQCf7o3_AyJylHKfSw";
    

    ///
    /// Price Format
    /// -----------------
    /// Need to change according to your format that you need
    /// E.g.
    /// "#,##0.00"   => 2,555.00
    /// "##0.00"    => 2555.00
    /// "#.00"       => 2555.00
    /// "#,###."      => 2,555
    /// "#,##0.0"    => 2555.0
    /// "###"        => 255
    static  priceFormat : string = '#,##0.0';


    ///
    /// Profile Setting for Item Grid
    /// You can hide/show user profile at item grid
    /// show == show user profile
    /// hide == hide user profile
    ///
    static showUserProfile : string = 'show';


    ///
    /// Map Filter Setting
    ///
    static  noFilterWithLocationOnMap : Boolean= false;

    ///
    /// Promotion Date Pre-define Set
    ///
    /// Type 1 == Fixed Date, Type 2 == Custom Date with user input
    static promotionDays = [
        {
            id:"1", days:7, type:1
        },
        {
            id:"2", days:14, type:1
        },
        {
            id:"3", days:30, type:1
        },
        {
            id:"4", days:60, type:1
        },
        {
            id:"5", days:1, type:2
        }
    ];

    ///
    /// Default Mile for Nearest Search
    ///
    static mile = "8";

    
    

    ///
    /// For default language change, please check
    /// LanguageFragment for language code and country code
    /// ..............................................................
    /// Language             | Language Code     | Country Code
    /// ..............................................................
    /// "English"            | "en"              | "US"
    /// "Arabic"             | "ar"              | "DZ"
    /// "India (Hindi)"      | "hi"              | "IN"
    /// "German"             | "de"              | "DE"
    /// "Spainish"           | "es"              | "ES"
    /// "French"             | "fr"              | "FR"
    /// "Indonesian"         | "id"              | "ID"
    /// "Italian"            | "it"              | "IT"
    /// "Japanese"           | "ja"              | "JP"
    /// "Korean"             | "ko"              | "KR"
    /// "Malay"              | "ms"              | "MY"
    /// "Portuguese"         | "pt"              | "PT"
    /// "Russian"            | "ru"              | "RU"
    /// "Thai"               | "th"              | "TH"
    /// "Turkish"            | "tr"              | "TR"
    /// "Chinese"            | "zh"              | "CN"
    /// ..............................................................
    ///
    static defaultLanguage = {
        languageCode : 'en',
        countryCode : 'US',
        name : 'English US',
        flag :  require('@/assets/flags/usflag.png')
    };
    static supportedLanguages = [
        { languageCode : 'en', countryCode : 'US', name : 'English US', flag: require('@/assets/flags/usflag.png')},
        { languageCode : 'ar', countryCode : 'DZ', name : 'Arabic', flag: require('@/assets/flags/dzFlag.png')},
        { languageCode : 'hi', countryCode : 'IN', name : 'Hindi', flag: require('@/assets/flags/inFlag.jpeg')},
        { languageCode : 'de', countryCode : 'DE', name : 'German', flag: require('@/assets/flags/germanFlag.jpg')},
        { languageCode : 'es', countryCode : 'ES', name : 'Spainish', flag: require('@/assets/flags/spainFlag.jpg')},
        { languageCode : 'fr', countryCode : 'FR', name : 'French', flag: require('@/assets/flags/frenchFlag.jpg')},
        { languageCode : 'id', countryCode : 'ID', name : 'Indonesian', flag: require('@/assets/flags/indonesiaFlag.jpg')},
        { languageCode : 'it', countryCode : 'IT', name : 'Italian', flag: require('@/assets/flags/italyFlag.jpg')},
        { languageCode : 'ja', countryCode : 'JP', name : 'Japanese', flag: require('@/assets/flags/japanFlag.jpg')},
        { languageCode : 'ko', countryCode : 'KR', name : 'Korean', flag: require('@/assets/flags/southkoreaFlag.jpg')},
        { languageCode : 'ms', countryCode : 'MY', name : 'Malay', flag: require('@/assets/flags/malaysiaFlag.jpg')},
        { languageCode : 'pt', countryCode : 'PT', name : 'Potuguese', flag: require('@/assets/flags/potugueseFlag.jpg')},
        { languageCode : 'ru', countryCode : 'RU', name : 'Russian', flag: require('@/assets/flags/russiaFlag.jpg')},
        { languageCode : 'th', countryCode : 'TH', name : 'Thai', flag: require('@/assets/flags/thaiFlag.jpg')},
        { languageCode : 'tr', countryCode : 'TR', name : 'Turkish', flag: require('@/assets/flags/turkishFlag.jpg')},
        { languageCode : 'zh', countryCode : 'CN', name : 'Chinese', flag: require('@/assets/flags/chinaFlag.jpg')}
    ];
        

    /// 
    /// Debug Mode
    ///
    static isDebugMode : Boolean = false;

    /// 
    /// Video Setting
    ///
    static isEnableVideoSetting : Boolean = true;
    
    ///
    /// Notification Setting
    ///
    static enableNotification = 'true';

    static fcmServerKey : string = 'AAAANv6tZn4:APA91bEi1HbRNAYUNQYCcrrt_aJXWnjN24lbhJF-M7gvKmXMtImTcBf6Eejv3JZ4R5Qn_7k38C54OHchnFYDwqwB97AkbWA4jTRQrG7DZUhQMZKLpqEKfV43nDn4pQMvWz7JhFsmk2G6';
    
    static firebaseWebPushKeyPair : string = 'BKsb1nDHgAxHTLwQ-7MUelUfg-NOnoAoG1CeYBVUo0ynWJeIiZbxsxmL8JGX6hWS_Gy8uKhatVFTJvvzT-ukf40';
 
    ///
    /// Adsense Setting
    ///
    static adClient = "ca-pub-9555618104694400";
    static adSlot = "5781624347";
    
}
